
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import DialogClosable from '@/mixins/dialog-closable';
import Contact from '@/model/Contact';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';
import Country from '@/model/Country';
import Reservation from '@/model/Reservation';
import { contactTitleItems } from '@/util/codes';
import UnsavedGuard from '@/mixins/unsaved-guard';

export default Vue.extend({
  name: 'ContactDetailsDialog',
  mixins: [DialogClosable, UnsavedGuard],
  props: {
    contact: { type: Object as PropType<Contact|null>, required: false, default: null },
  },
  data() {
    return {
      contactTitles: contactTitleItems(),
      newc: new Contact(), // direct editing: new contact duplicated from prop if any
      oldc: new Contact(), // reservation editing: old contact from reservation
      confirmationDialogVisible: false,
    };
  },
  computed: {
    isReservationEditing(): boolean {
      return this.contact === null;
    },
    r(): Reservation {
      return this.$tstore.getters.editReservation ?? new Reservation();
    },
    c(): Contact {
      if (!this.isReservationEditing) return this.newc; // direct contact editing
      return this.r.contact ?? new Contact(); // reservation editing
    },
    countries(): Country[] {
      return this.$tstore.getters.sortedCountries;
    },
    languages(): String[] {
      return this.$tstore.getters.sortedLocales;
    },
    totalVisitsText(): string {
      return this.c.reservationCount && this.c.reservationCount > 0
        ? this.$i18n.t('label.total_visits_x', [this.c.reservationCount]) as string
        : '';
    },
    // eslint-disable-next-line vue/no-unused-properties
    isChanged(): boolean {
      return !isModelEqualToModel(this.c, this.oldc);
    },
    isReservationsAllowed(): boolean {
      return this.$tstore.getters.isReservationsAllowed;
    },
    defaultCC(): string {
      const { account } = this.$tstore.state.settings;
      return this.newc.country?.code || account.countryCode || '';
    },
  },
  watch: {
    visible(value: boolean) {
      if (value) {
        if (!this.isReservationEditing) { // direct contact editing
          this.newc = cloneModel(this.contact!);
          this.oldc = cloneModel(this.contact!);
        } else this.oldc = cloneModel(this.r.contact); // reservation editing
      }
    },
  },
  created() {
    this.oldc = cloneModel(this.r.contact);
  },
  mounted() {
    console.log('Contact Details mounted: ', this.contact);
    if (!this.isReservationEditing) { // direct contact editing
      this.newc = cloneModel(this.contact!);
      this.oldc = cloneModel(this.contact!);
    } else this.oldc = cloneModel(this.r.contact); // reservation editing
  },
  methods: {
    formatBirthday(d: Date): string | null {
      return this.$localized.dateText(d);
    },
    dateOfBirthChange(date: Date) {
      this.c.dateOfBirth = date ?? undefined;
    },
    async save() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) { return; }

      if (!this.isReservationEditing) this.$emit('save', this.newc); // direct contact editing
      else this.$emit('close', false); // reservation editing
    },
    close() {
      // TODO alert if changed, discard changes
      if (this.isChanged) {
        this.confirmationDialogVisible = true;
      } else {
        this.cancel();
      }
    },
    cancel() {
      if (this.isReservationEditing) this.r.contact = this.oldc; // reservation editing
      this.$emit('close', false);
    },
  },
});
