// eslint-disable-next-line import/prefer-default-export
export function isSafariOS(): boolean {
  const ua = navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
}

export function isSafari(): boolean {
  const ua = navigator.userAgent;
  return !!ua.match(/webkit\W(?!.*chrome).*safari\W/i);
}

export function capitalize(s:string | null): string {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
